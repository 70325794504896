<template>
  <div class="d-flex justify-end">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon :disabled="disableState">
          <v-icon small @click="editState = !editState">mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
    </v-menu>
    <ClaimEditWalletDialog :state="editState" :winner="winner" @updateWalletDone="$emit('updateWalletDone', $event)" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    ClaimEditWalletDialog: () => import('@/components/dialogs/claimer/ClaimEditWalletDialog.vue'),
  },
  props: {
    winner: Object,
    disableState: Boolean,
  },
  computed: {
    ...mapState('auth', ['account']),
  },
  data() {
    return {
      editState: false,
    }
  },
}
</script>
